export class Store {
    save(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    get(key) {
        try {
            const item = localStorage.getItem(key);
            return JSON.parse(item)
        } catch (error) {
            console.error('Error getting key', error);
        }
    }

    delete(key) {
        try {
            localStorage.removeItem(key);
        } catch (error) {
            console.error('Error deleting key', error);
        }
    }
}
import axios from "axios";
import { Configs } from "../configs/configs.env";
import { Store } from "../store";
import { StoreKeysConstants } from "../constants/store.keys";

const store = new Store();
export class AppRepository {
  #axios;
  constructor() {
    const authToken = store.get(StoreKeysConstants.AUTH_TOKEN);
    this.#axios = axios.create({
      baseURL: Configs.GATEWAY_SERVICE_URL + "/apps",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      },
    });
  }

  async createApp({ name, domain }) {
    try {
      const response = await this.#axios.post("/", { name, domain });
      return response.data?.data;
    } catch (error) {
      if (error?.response) {
        return { error: error.response?.data?.message };
      }
    }
  }

  async getMyApps() {
    try {
      const response = await this.#axios.get("/me");
      return response.data?.data;
    } catch (error) {
      console.error(error);
    }
  }

  async deleteApp(id) {
    try {
      await this.#axios.delete("/id/"+id);
    } catch (error) {
      console.error(error);
    }
  }
}

import React, { lazy, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import PageLoader from "./components/Loaders/PageLoader.jsx";

const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login.jsx"));
const RequestAccess = lazy(() => import("./pages/RequestAccess.jsx"));
const CreateAccount = lazy(() => import("./pages/CreateAccount.jsx"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));

function App() {
  const [isLoading, setIsLoading] = useState(true); 
  const timeoutPageLoader = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }

  useEffect(() => {
    timeoutPageLoader();
  });
  return (
    <>
    {
      isLoading && <PageLoader /> || (
        <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <Route path="/access-request" component={RequestAccess} />
          <Route path="/login" component={Login} />
          <Route path="/create-account" component={CreateAccount} />
          <Route path="/forgot-password" component={ForgotPassword} />

          {/* Place new routes over this */}
          <Route path="/app" component={Layout} />
          {/* If you have an index page, you can remothis Redirect */}
          <Redirect exact from="/" to="/access-request" />
        </Switch>
      </Router>
      )
    }
    </>
  );
}
//
export default App;

import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { Configs } from "../configs/configs.env";
import { Store } from "../store";
import { StoreKeysConstants } from "../constants/store.keys";
import { AppRepository } from "../repositories/app.repository";

const { GATEWAY_SERVICE_URL } = Configs;

const AuthContext = createContext();
const store = new Store();

const AuthProvider = ({ children }) => {
  const authUrl = GATEWAY_SERVICE_URL.split("/api/")[0] + "/auth";
  const sessionUrl = GATEWAY_SERVICE_URL.split("/api/")[0] + "/sessions";
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
    setLoading(false);
  }, []);

  const requestSignInOtp = async (email) => {
    try {
      const response = await axios.post(authUrl + "/2", { email });
      const userData = response.data;
      return { message: userData?.message };
    } catch (error) {
      if (error?.response) {
        return { error: error.response?.data?.message };
      }
      console.error("Access request failed", error);
    }
  };

  const saveCustomerApp = async () => {
    const repo = new AppRepository();
    const apps = await repo.getMyApps();
    const app = apps[0];
    store.save(StoreKeysConstants.APP_DATA, app);
  };

  const login = async (email, otp) => {
    try {
      const response = await axios.post(authUrl + "/2/sign-in", { email, otp });
      const userData = response.data;
      // check
      store.save(
        StoreKeysConstants.AUTH_TOKEN,
        userData?.data?.tokens?.accessToken
      );
      store.save(StoreKeysConstants.USER_DATA, userData?.data);
      await saveCustomerApp();
      return { message: userData?.message };
    } catch (error) {
      if (error?.response) {
        return { error: error.response?.data?.message };
      }
      console.error("Login failed", error);
    }
  };

  const logout = async () => {
    try {
      const user = store.get(StoreKeysConstants.USER_DATA); 
      const sessionId = user?.session.id;
      await axios.delete(sessionUrl + '/id/'+ sessionId);
      setUser(null);
      store.delete(StoreKeysConstants.AUTH_TOKEN);
      store.delete(StoreKeysConstants.USER_DATA);
      store.delete(StoreKeysConstants.APP_DATA);
      return true;
    } catch (error) {
      if (error?.response) {
        return { error: error.response?.data?.message };
      }
      console.error("Logout failed", error);
    }
  };

  const register = async (businessName, email, phone) => {
    try {
      const response = await axios.post(authUrl + "/register", {
        businessName,
        email,
        phone,
      });

      const userData = response.data;

      setUser(userData);
      store.save(
        StoreKeysConstants.AUTH_TOKEN,
        userData?.data?.tokens?.accessToken
      );
      store.save(StoreKeysConstants.USER_DATA, userData?.data);
      return { status: "success", message: "Registration successful" };
    } catch (error) {
      console.error("Registration failed:", error.message);
      return {
        status: "error",
        message: error?.response?.data?.message || "Registration failed",
        error: error.response?.data?.message
      };
    }
  };

  return (
    <AuthContext.Provider
      value={{ user, loading, login, requestSignInOtp, register, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };

import React from 'react'
import loader from './output-onlinegiftools.gif';
import { ThemeConstants } from '../../constants/theme';
import { ThemeUtils } from '../../utils/theme';

export default function PageLoader() {
    const _isDarkMode = ThemeUtils.isDarkMode();
    return (
        <div className="loader-backdrop" style={{ background: _isDarkMode ? ThemeConstants.DARK : ThemeConstants.LIGHT }}>
          <img
            width="100px"
            src={loader}
            alt=""
          />
        </div>
    );
} 
